<template>
  <div class="agenda-lancamento-container">
    <div class="row">
      <!-- Calendário e seleção de datas -->
      <div class="col-12 col-lg-5 mb-4">
        <div class="card h-100">
          <div class="card-header bg-light">
            <h5 class="mb-0">
              <i class="fa fa-calendar-alt mr-2"></i>
              Seleção de Datas
            </h5>
          </div>
          <div class="card-body d-flex flex-column">
            <div class="calendar-wrapper mx-auto">
              <vc-calendar
                mode="date"
                color="red"
                :attributes="attributes"
                @dayclick="onDayclick"
                class="w-100"
                :rows="$screens({ default: 1, md: 2 })"
                :locale="{ firstDayOfWeek: 1, masks: { weekdays: 'W' } }"
              />
            </div>

            <div
              class="mt-3 text-center"
              v-if="lancamento.data.length > 0"
            >
              <h6>Datas selecionadas: {{ lancamento.data.length }}</h6>
              <div class="selected-dates-container">
                <b-badge
                  v-for="(date, index) in lancamento.data"
                  :key="index"
                  variant="primary"
                  class="mr-1 mb-1 py-2 px-3"
                >
                  {{ formatDate(date.date) }}
                  <b-button
                    size="sm"
                    variant="link"
                    class="p-0 ml-1 text-white"
                    @click.stop="removeDateByIndex(index)"
                  >
                    <i class="fa fa-times-circle"></i>
                  </b-button>
                </b-badge>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Informações da agenda -->
      <div class="col-12 col-lg-7">
        <div class="row">
          <!-- Médicos e Especialidade -->
          <div class="col-12 mb-4">
            <div class="">
              <div class="card-header bg-light">
                <h5 class="mb-0">
                  <i class="fa fa-user-md mr-2"></i>
                  Médico e Especialidade
                </h5>
              </div>
              <div class="card-body">
                <select-medico-especialidade
                  @especialidade="onChangeEspecialidade($event)"
                  :especialidade_id="lancamento.especialidade"
                  @medico="onChangeMedico($event)"
                  @especialidadeComplete="especialiadeComplete = $event"
                  :medico_id="lancamento.medico"
                  multiple-medico
                ></select-medico-especialidade>
              </div>
            </div>
          </div>

          <!-- Clínica e dilatação (oftalmologia) -->
          <div class="col-12 mb-4">
            <div class="card shadow-sm">
              <div class="card-header bg-light">
                <h5 class="mb-0">
                  <i class="fa fa-clinic-medical mr-2"></i>
                  Clínica e Configurações
                </h5>
              </div>
              <div class="card-body">
                <!-- Dilatação para oftalmologia -->
                <div
                  class="mb-4"
                  v-if="
                    especialiadeComplete.especialidade &&
                    especialiadeComplete.especialidade
                      .toLowerCase()
                      .indexOf('oftalmo') >= 0
                  "
                >
                  <h6 class="border-bottom pb-2 mb-3">
                    Configuração de Oftalmologia
                  </h6>
                  <b-form-group>
                    <b-form-checkbox
                      v-model="lancamento.faz_dilatacao"
                      :unchecked-value="0"
                      :value="1"
                      switch
                      >Médico faz dilatação?</b-form-checkbox
                    >
                    <b-form-group class="mt-2">
                      <b-form-input
                        v-model="lancamento.tempo_dilatacao"
                        :disabled="!lancamento.faz_dilatacao"
                        type="time"
                        step="0"
                        locale="br"
                      ></b-form-input>
                      <small class="form-text text-muted"
                        >Tempo da Dilatação</small
                      >
                    </b-form-group>
                  </b-form-group>
                </div>

                <!-- Anamnese -->
                <div
                  v-if="anamneses && anamneses.length > 0"
                  class="mb-4"
                >
                  <h6 class="border-bottom pb-2 mb-3">Anamnese</h6>
                  <label class="d-flex align-items-center">
                    <span>Selecione a anamnese</span>
                    <b-spinner
                      v-if="loading"
                      small
                      size="sm"
                      class="ml-2"
                    />
                  </label>
                  <v-select
                    :options="anamneses"
                    v-model="lancamento.anamnese"
                    :reduce="(anamnese) => anamnese.id"
                    type="text"
                    label="nome"
                    placeholder="Selecione uma anamnese"
                  >
                    <template v-slot:no-options>
                      <em style="opacity: 0.5"
                        >Não encontramos nenhuma anamnese 😕.</em
                      >
                    </template>
                  </v-select>
                </div>

                <!-- Clínicas -->
                <div class="mt-3">
                  <h6 class="border-bottom pb-2 mb-3">Clínica</h6>
                  <select-clinicas
                    consultorio
                    :valueConsultorio="lancamento.consultorio"
                    @consultorio="lancamento.consultorio = $event"
                    multiple
                    v-model="lancamento.clinicas"
                  ></select-clinicas>
                </div>
              </div>
              <div
                class="col-12 mb-4"
                v-if="
                  lancamento.consultorio &&
                  lancamento.medico &&
                  lancamento.medico.length > 0
                "
              >
                <div class="card shadow-sm">
                  <div class="card-header bg-light">
                    <h5 class="mb-0">
                      <i class="fa fa-map-marker-alt mr-2"></i>
                      Endereços dos Consultórios
                    </h5>
                  </div>
                  <div class="card-body p-3">
                    <div
                      v-if="carregandoEnderecos"
                      class="text-center py-3"
                    >
                      <b-spinner variant="primary"></b-spinner>
                      <p class="mt-2 mb-0">
                        Carregando endereços dos médicos...
                      </p>
                    </div>
                    <div v-else>
                      <div
                        v-for="(medicoId, index) in lancamento.medico"
                        :key="index"
                        class="mt-2 mb-3 pb-3 border-bottom"
                      >
                        <div class="d-flex align-items-center mb-2">
                          <b-avatar
                            :text="getMedicoInitials(medicoId)"
                            variant="primary"
                            class="mr-2"
                          ></b-avatar>
                          <strong>{{ getMedicoNome(medicoId) }}</strong>
                        </div>
                        <div class="row">
                          <div class="col">
                            <label class="d-flex align-items-center">
                              <small class="text-muted"
                                >Endereço de atendimento</small
                              >
                              <b-spinner
                                v-if="carregandoEnderecoMedico[medicoId]"
                                small
                                variant="primary"
                                class="ml-2"
                              ></b-spinner>
                            </label>
                            <v-select
                              :options="medicosEnderecos[medicoId] || []"
                              v-model="lancamento.medicos_enderecos[medicoId]"
                              placeholder="Selecione o endereço deste médico"
                              :class="{
                                'is-invalid':
                                  enderecosSelecionadosValidados &&
                                  !lancamento.medicos_enderecos[medicoId],
                              }"
                            >
                              <template v-slot:no-options>
                                <em style="opacity: 0.5"
                                  >Nenhum endereço disponível para este
                                  médico</em
                                >
                              </template>
                            </v-select>
                            <div
                              v-if="
                                enderecosSelecionadosValidados &&
                                !lancamento.medicos_enderecos[medicoId]
                              "
                              class="invalid-feedback d-block"
                            >
                              Por favor, selecione um endereço para este médico.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Endereços dos médicos -->
          </div>

          <!-- Configuração de Horários -->
          <div class="col-12 mb-4">
            <div class="card shadow-sm">
              <div class="card-header bg-light">
                <h5 class="mb-0">
                  <i class="fa fa-clock mr-2"></i>
                  Configuração de Horários
                </h5>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-12 col-md-4 mb-3">
                    <label class="font-weight-bold">Hora Início</label>
                    <b-input-group>
                      <b-form-input
                        v-mask="'##:##'"
                        type="text"
                        ref="hora_inicio"
                        placeholder="HH:mm"
                        v-model="lancamento.hora_inicio"
                        class="hora-input"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-form-timepicker
                          button-only
                          right
                          locale="pt-br"
                        ></b-form-timepicker>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 mb-3">
                    <label class="font-weight-bold">Intervalo (min)</label>
                    <b-form-input
                      type="text"
                      class="form-control"
                      v-model="lancamento.intervalo"
                      ref="intervalo"
                      placeholder="Ex: 15"
                    />
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 mb-3">
                    <label class="font-weight-bold">Qtd. Espera</label>
                    <b-form-input
                      type="number"
                      class="form-control"
                      v-model="lancamento.espera_qtd"
                      placeholder="0"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-4 mb-3">
                    <label class="font-weight-bold">Qtd. Consulta</label>
                    <b-form-input
                      type="number"
                      class="form-control"
                      ref="consulta_qtd"
                      v-model="lancamento.consulta_qtd"
                      placeholder="0"
                    />
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 mb-3">
                    <label class="font-weight-bold">Qtd. Retorno</label>
                    <b-form-input
                      type="number"
                      class="form-control"
                      ref="retorno_qtd"
                      v-model="lancamento.retorno_qtd"
                      placeholder="0"
                    />
                  </div>
                  <div class="col-12 col-md-4 mb-3">
                    <label class="font-weight-bold">Total</label>
                    <b-form-input
                      :value="total"
                      type="text"
                      class="form-control"
                      readonly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Observações e Notificações -->
          <div class="col-12 mb-4">
            <div class="card shadow-sm">
              <div class="card-header bg-light">
                <h5 class="mb-0">
                  <i class="fa fa-bell mr-2"></i>
                  Observações e Notificações
                </h5>
              </div>
              <div class="card-body">
                <div class="mb-4">
                  <label class="font-weight-bold">Observação Agenda</label>
                  <b-textarea
                    v-model="lancamento.obs"
                    placeholder="Digite observações para esta agenda..."
                    rows="3"
                    class="mb-3"
                  />
                </div>
                <div>
                  <NotificacaoConfig
                    v-model="lancamento.alerta_config"
                    :title="' ao agendar'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal para personalização de horários -->
    <b-modal
      id="modal-horarios-table"
      title="Personalização de Horários"
      size="lg"
      hide-footer
      hide-header
      no-enforce-focus
      class="horarios-modal"
    >
      <div class="modal-header custom-modal-header">
        <h5 class="modal-title">
          <i class="fa fa-clock mr-2"></i>
          Personalização de Horários
        </h5>
        <button
          type="button"
          class="close"
          @click="$bvModal.hide('modal-horarios-table')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-3">
        <b-btn
          variant="danger"
          @click="recriarHorarios"
          class="mb-3"
        >
          <i class="fa fa-sync mr-1"></i> Re-criar horários
        </b-btn>
        <AgendaHorariosTable
          :horariosProp="lancamento.horarios"
          v-on:fecharHorarios="$bvModal.hide('modal-horarios-table')"
          @personalizouHorarios="personalizarHorarios($event)"
        />
      </div>
    </b-modal>

    <!-- Barra de ações -->
    <div class="action-bar shadow-sm">
      <div class="container-fluid">
        <div class="d-flex justify-content-between align-items-center p-3">
          <b-button
            variant="outline-primary"
            size="md"
            @click="showModalHorarios()"
            :disabled="loading"
            class="mr-2"
          >
            <span v-if="!loading">
              <i class="fa fa-clock"></i> Personalizar Horários
            </span>
            <span v-if="loading">
              <b-spinner
                small
                size="sm"
              />
              Personalizar Horários...
            </span>
          </b-button>
          <div>
            <b-button
              variant="secondary"
              class="mr-2"
              :disabled="loading"
            >
              <i class="fas fa-ban"></i> Cancelar
            </b-button>
            <b-button
              variant="success"
              @click="salvar"
              :disabled="loading"
            >
              <div v-if="!loading"><i class="fa fa-save"></i> Salvar</div>
              <div v-if="loading"><b-spinner small /> Gerando...</div>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectMedicoEspecialidade from "../../common/SelectMedicoEspecialidade.vue";
import SelectClinicas from "../../common/SelectClinicas.vue";
import EmpresasLib from "@/libs/EmpresasLib";
import AgendasLib from "../../../libs/AgendasLib.js";
import AgendaHorariosTable from "../../Agendas/AgendaHorariosTable.vue";
import EntidadesLib from "@/libs/EntidadesLib";

import AnamneseLib from "@/libs/AnamneseLib.js";
import moment from "moment";
import NotificacaoConfig from "../../Notificacao/NotificacaoConfig.vue";
export default {
  components: {
    SelectMedicoEspecialidade,
    SelectClinicas,
    AgendaHorariosTable,
    NotificacaoConfig,
  },
  data() {
    return {
      lancamento: {
        medico: [],
        clinicas: [],
        especialidade: 0,
        data: [],
        consultorio: 0,
        anamnese: null,
        faz_dilatacao: 0,
        tempo_dilatacao: "00:00",
        hora_inicio: "",
        intervalo: "",
        espera_qtd: "",
        consulta_qtd: "",
        retorno_qtd: "",
        obs: "",
        total: "",
        horarios: [],
        alerta_config: {
          tipo: "Nenhum",
          usuarios: [],
          grupos: [],
        },
        medicos_enderecos: {}, // Para armazenar os endereços selecionados para cada médico
      },
      especialiadeComplete: {},
      loading: false,
      clinicas: [],
      anamneses: [],
      days: [],
      isRange: true,
      horariosGerados: false,
      medicosEnderecos: {}, // Armazenar os endereços disponíveis para cada médico
      medicos: {}, // Para armazenar os detalhes dos médicos
      carregandoEnderecos: false,
      carregandoEnderecoMedico: {},
      enderecosSelecionadosValidados: false,
    };
  },
  watch: {
    "lancamento.consulta_qtd": function (o, n) {
      o;
      n;
      // if (n && o && n != "" && o != "" && n != o) this.qtdHorariosAlterada();
    },
    "lancamento.retorno_qtd": function (o, n) {
      o;
      n;
      // if (n && o && n != "" && o != "" && n != o) this.qtdHorariosAlterada();
    },
  },
  mounted() {
    this.carregaClinicas();
    this.carregaAnamneses();

    // Inicializar o objeto de endereços dos médicos
    this.lancamento.medicos_enderecos = {};
  },
  methods: {
    async recriarHorarios() {
      await this.gerarHorarios(true);
      this.$bvModal.hide("modal-horarios-table");
      await this.$nextTick();
      this.showModalHorarios();
    },
    async qtdHorariosAlterada() {
      if (this.horariosGerados) {
        await new Promise((resolve) => {
          this.$swal({
            title: "Atenção",
            text: "Você já editou os horarios deseja re-criar todos os horarios?",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
          }).then(async (r) => {
            if (r.isConfirmed) {
              this.horariosGerados = false;
              this.$nextTick();
              this.gerarHorarios();
            }
            resolve();
          });
        });
      }
    },
    async onChangeMedico(medico) {
      this.lancamento.medico = medico;
      console.log("medico", medico);

      // Carregar os endereços para cada médico
      this.carregarEnderecosMedicos(medico);
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    removeDateByIndex(index) {
      this.lancamento.data.splice(index, 1);
    },

    async carregarEnderecosMedicos(medicosIds) {
      if (!medicosIds || medicosIds.length === 0) return;

      this.carregandoEnderecos = true;

      for (const medicoId of medicosIds) {
        // Verificar se já carregamos os endereços deste médico
        if (!this.medicosEnderecos[medicoId]) {
          try {
            // Indicar que está carregando para este médico específico
            this.$set(this.carregandoEnderecoMedico, medicoId, true);

            const response = await EntidadesLib.getById(medicoId);
            if (response && response.data && response.data.length > 0) {
              const medico = response.data[0];
              this.medicos[medicoId] = medico; // Salvar os detalhes do médico

              // Formatar os endereços como no AgendasForm.vue
              const enderecos = medico.enderecos.map(
                (en) =>
                  `${en.logradouro} ${en.numero}, ${en.bairro} - ${en.cidade} - ${en.uf}`
              );

              // Definir os endereços para esse médico
              this.$set(this.medicosEnderecos, medicoId, enderecos);

              // Definir o primeiro endereço como padrão, se existir
              if (enderecos.length > 0) {
                this.$set(
                  this.lancamento.medicos_enderecos,
                  medicoId,
                  enderecos[0]
                );
              }
            }
          } catch (error) {
            console.error(
              `Erro ao carregar endereços do médico ${medicoId}:`,
              error
            );
          } finally {
            // Remover indicador de carregamento para este médico
            this.$set(this.carregandoEnderecoMedico, medicoId, false);
          }
        }
      }

      this.carregandoEnderecos = false;
    },

    getMedicoNome(medicoId) {
      return this.medicos[medicoId]?.razao_social || `Médico ${medicoId}`;
    },

    getMedicoInitials(medicoId) {
      const nome = this.getMedicoNome(medicoId);
      return nome
        .split(" ")
        .filter((part) => part.length > 0)
        .map((part) => part[0])
        .slice(0, 2)
        .join("")
        .toUpperCase();
    },
    personalizarHorarios(horarios) {
      // console.log("horarios recebidos", horarios);
      this.$forceUpdate();

      // this.agenda.consulta_qtd =
      //   parseInt(this.agenda.consulta_qtd) + parseInt(horarios.horariosAdded);
      this.lancamento.horarios = Object.assign([], horarios.horarios);

      const _horarios = this.lancamento.horarios.filter(
        (h) => h._rowStatus !== "deleted"
      );
      this.lancamento.retorno_qtd = _horarios.filter(
        (h) => h.retorno == 1
      ).length;

      this.lancamento.consulta_qtd = _horarios.filter((h) => !h.retorno).length;
      this.lancamento.total = this.total;
      // console.log("horarios da lancamento", this.agenda.horarios);
      this.$bvModal.hide("modal-horarios-table");
    },
    async showModalHorarios() {
      await this.qtdHorariosAlterada();
      if (!this.horariosGerados) {
        if ((await this.gerarHorarios()) === true) {
          console.debug("Vai abrir o modal de horários");
          this.$bvModal.show("modal-horarios-table");
        }
      } else {
        this.$bvModal.show("modal-horarios-table");
      }
    },
    onChangeEspecialidade(especialidade) {
      // console.log("onChangeEspecialidade", especialidade);
      this.lancamento.especialidade = especialidade;
    },
    async carregaClinicas() {
      this.clinicas = (await EmpresasLib.getEmpresasGrupo()).map((emp) => {
        return { ...emp, nome: emp.fantasia + " (" + emp.sigla + ")" };
      });
      // this.empresa = this.empresas[0];
    },
    async carregaAnamneses() {
      this.anamneses = await AnamneseLib.get();
    },

    async gerarHorarios(force = false) {
      console.debug(
        "Gerar horarios foi chamado",
        "force? ",
        force,
        "os horários já foram gerados?",
        this.horariosGerados
      );

      if (this.horariosGerados && !force) {
        return;
      }

      let {
        hora_inicio,
        intervalo,
        // total,
        consulta_qtd,
        retorno_qtd,
      } = this.lancamento;

      let errors = [];
      if (!hora_inicio) {
        errors.push("Preencha o horário de inicio da agenda!");
      }
      if (!intervalo) {
        errors.push("Preencha o intervalo da agenda!");
      }
      if (!consulta_qtd) {
        errors.push("Preencha a quantia de consultas da agenda!");
      }
      if (!retorno_qtd) {
        this.lancamento.retorno_qtd = 0;
        // errors.push("Preencha a quantia de retornos da agenda!");
      }
      if (errors.length > 0) {
        let errDesc = errors.reduce((it, vl) => {
          it += vl + " <hr />";
          return it;
        }, "");
        // console.log(errDesc);
        this.$swal({
          title: "Atenção",
          html: `<b>Não foi possível gerar os horários... Os detalhes a seguir precisam de atenção</b><hr/><span class='text-left'>${errDesc}</span>`,
          icon: "error",
        });
        return false;
      } else {
        this.lancamento.horarios = [];
        this.$forceUpdate();
        await this.$nextTick();
        // await new Promise((resolve) => setTimeout(resolve, 2000));
        this.lancamento.total = this.total;

        let horarioAtual = hora_inicio.split(":");
        horarioAtual.splice(2, 1);
        horarioAtual = horarioAtual.join(":");

        //   if (
        //     this.horariosFiltrados.length !== total ||
        //     (this.horarioInicial != horarioAtual && !isNaN(this.agenda.id))
        //   )
        {
          this.lancamento.hora_inicio = horarioAtual;

          // if (this.horariosFiltrados.length > 0) {
          //   this.lancamento.horarios.forEach((_, i) => {
          //     this.$set(this.lancamento.horarios[i], "_rowStatus", "deleted");
          //   });
          // }

          for (let i = 0; i < consulta_qtd; i++) {
            const horarios = this.lancamento.horarios.filter(
              (h) => h._rowStatus !== "deleted" || !h._rowStatus
            );
            let horario =
              horarios.length > 0
                ? moment(
                    horarios[horarios.length - 1].horario.replace(":", ""),
                    "hmm"
                  )
                    .add(intervalo, "minutes")
                    .format("HH:mm")
                : hora_inicio;

            this.lancamento.horarios.push({
              horario,
              id_paciente: null,
              paciente: null,
              telefone: null,
              idade: null,
              cpf: null,
              obs: null,
              retorno: null,
              agendado: 0,
              compareceu: 0,
              procedimento: 0,
              confirmou: 0,
              questionario: {},
              id_horario_retorno: null,
              _rowStatus: "added",
            });
          }

          for (let i = 0; i < retorno_qtd; i++) {
            const horarios = this.lancamento.horarios.filter(
              (h) => h._rowStatus !== "deleted" || !h._rowStatus
            );
            // if(horarios.length > 0 && i === 0 )i = 1
            let horario =
              horarios.length > 0
                ? moment(
                    horarios[horarios.length - 1].horario.replace(":", ""),
                    "hmm"
                  )
                    .add(intervalo, "minutes")
                    .format("HH:mm")
                : hora_inicio;
            this.lancamento.horarios.push({
              horario,
              id_paciente: null,
              paciente: null,
              telefone: null,
              idade: null,
              cpf: null,
              obs: null,
              retorno: 1,
              agendado: 0,
              compareceu: 0,
              procedimento: 0,
              confirmou: 0,
              questionario: {},
              id_horario_retorno: null,
              _rowStatus: "added",
            });
          }
        }
        this.$forceUpdate();
        // console.log("Gerou os horários", this.lancamento.horarios);
        this.horariosGerados = true;
        return true;
      }
    },
    async salvar() {
      console.log("salvar", this.lancamento, this.medicosEnderecos);
      this.loading = true;
      this.lancamento.total = this.total;

      // Verificar se todos os médicos possuem endereços selecionados
      if (this.lancamento.medico && this.lancamento.medico.length > 0) {
        const medicosSemEndereco = this.lancamento.medico.filter(
          (medicoId) => !this.lancamento.medicos_enderecos[medicoId]
        );

        if (medicosSemEndereco.length > 0) {
          this.enderecosSelecionadosValidados = true;
          const nomesMedicos = medicosSemEndereco
            .map((medicoId) => this.getMedicoNome(medicoId))
            .join(", ");
          this.$swal({
            title: "Atenção",
            html: `<b>Por favor, selecione um endereço para cada médico:</b><hr/><span class='text-left'>${nomesMedicos}</span>`,
            icon: "warning",
          });
          this.loading = false;
          return;
        }
      }

      // Verificar se pelo menos uma data foi selecionada
      if (!this.lancamento.data || this.lancamento.data.length === 0) {
        this.$swal({
          title: "Atenção",
          html: "<b>Por favor, selecione ao menos uma data no calendário</b>",
          icon: "warning",
        });
        this.loading = false;
        return;
      }

      // console.log(12);
      await this.gerarHorarios();

      try {
        console.debug("Vai salvar: ", this.lancamento);
        let result = await AgendasLib.lancarMultiplos(this.lancamento);
        // console.log("result", result);
        if (result && result.success) {
          this.showToast("success", "Lançamentos foram gerados!");
          this.$emit("success");
        } else {
          this.showToast("error", result.message);
        }
      } catch (error) {
        this.showToast("error", error);
      }

      this.loading = false;
    },
    onDayclick(day) {
      const idx = this.lancamento.data.findIndex((d) => d.id === day.id);
      if (idx >= 0) {
        this.lancamento.data.splice(idx, 1);
      } else {
        this.lancamento.data.push({
          id: day.id,
          date: day.date,
        });
      }
    },
  },
  computed: {
    dates() {
      return this.lancamento.data.map((d) => d.date);
    },
    attributes() {
      return this.dates.map((d, i) => ({
        key: i,
        dates: d,
        highlight: true,
        order: i,
      }));
    },
    total() {
      return (
        (this.lancamento.consulta_qtd
          ? parseInt(this.lancamento.consulta_qtd)
          : 0) +
        (this.lancamento.retorno_qtd
          ? parseInt(this.lancamento.retorno_qtd)
          : 0)
      );
    },
  },
};
</script>

<style scoped>
.agenda-lancamento-container {
  position: relative;
  padding-bottom: 80px;
  min-height: calc(100vh - 70px);
}

.calendar-wrapper {
  width: 100%;
  max-width: 350px;
}

.action-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  border-top: 1px solid #e9ecef;
  z-index: 1000;
}

.card {
  transition: box-shadow 0.3s ease;
  border: none;
  border-radius: 0.5rem;
}

.card:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.card-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.75rem 1.25rem;
}

.is-invalid {
  border-color: #dc3545;
}

.hora-input {
  font-family: monospace;
  letter-spacing: 1px;
}

.selected-dates-container {
  max-height: 150px;
  overflow-y: auto;
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: #f8f9fa;
}

/* Modal personalizado */
.custom-modal-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.horarios-modal >>> .modal-content {
  border-radius: 0.5rem;
  overflow: hidden;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .agenda-lancamento-container {
    padding-bottom: 100px;
  }

  .action-bar {
    padding: 0.5rem;
  }
}
</style>
